var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  'use strict';

  $(document).on('product.skuDisplay', '.js-product', function (e, skuBaseId) {
    var $gallery = $('.js-product-gallery', $(this));

    if ($gallery.length < 1) {
      return null;
    }
    var prodId = $gallery.data('product-id');

    if (prodcat.data.isPaletteMultiSku(prodId) || prodcat.data.isPaletteMultiProduct(prodId)) {
      Drupal.behaviors.productGalleryV1.displaySkuImage($gallery, skuBaseId);
    } else {
      Drupal.behaviors.productGalleryV1.displaySmoosh($gallery, skuBaseId);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $gallery = $('.js-product-gallery', $(this));

    if ($gallery.length < 1) {
      return null;
    }
    var prodId = $gallery.data('product-id');

    if (prodcat.data.isPaletteMultiSku(prodId) || prodcat.data.isPaletteMultiProduct(prodId)) {
      Drupal.behaviors.productGalleryV1.displaySkuImage($gallery, skuBaseId);
    } else if (
      !!prodcat.data.getProduct(prodId).sized &&
      !!$('.js-product-gallery__photo--product', $gallery).length &&
      prodcat.data.getProduct(prodId).skus.length > 1
    ) {
      Drupal.behaviors.productGalleryV1.displaySkuImageSized($gallery, skuBaseId);
    } else {
      Drupal.behaviors.productGalleryV1.displaySmoosh($gallery, skuBaseId);
    }
    if ($gallery.hasClass('product-gallery--shaded') || $gallery.hasClass('js-product-gallery-sized')) {
      var $largeImages = $('.js-product-gallery__photo--product', $gallery);
      var $thumbnails = $('.js-product-gallery__img-thumb--product', $gallery);
      var skuData = prodcat.data.getSku(skuBaseId);
      var productCode = skuData.PRODUCT_CODE;

      $largeImages.each(function () {
        var src = $(this).attr('src');

        if (src) {
          src = src.replace(/(.+gg_prod_)([A-Z0-9]+)(_.+)/, '$1' + productCode + '$3');
          $(this).attr('src', src);
        } else {
          var data_src = $(this).attr('data-src');

          data_src = data_src.replace(/(.+gg_prod_)([A-Z0-9]+)(_.+)/, '$1' + productCode + '$3');
          $(this).attr('data-src', data_src);
        }

        var zoomImage = $(this).attr('data-zoom-image');

        if (!!zoomImage) {
          zoomImage = zoomImage.replace(/(.+gg_prod_)([A-Z0-9]+)(_.+)/, '$1' + productCode + '$3');
          $(this).attr('data-zoom-image', zoomImage);
        }
      });

      $thumbnails.each(function () {
        var src = $(this).attr('src');

        if (src) {
          src = src.replace(/(.+gg_prod_)([A-Z0-9]+)(_.+)/, '$1' + productCode + '$3');
          $(this).attr('src', src);
        } else {
          var data_src = $(this).attr('data-src');

          data_src = data_src.replace(/(.+gg_prod_)([A-Z0-9]+)(_.+)/, '$1' + productCode + '$3');
          $(this).attr('data-src', data_src);
        }
      });
    }
  });

  $(document).on(
    'click',
    '.js-product-gallery__div-thumb--product, .js-product-gallery__div-thumb--smoosh',
    function (e) {
      e.preventDefault();
      var $thumbLink = $(this);
      var idx = $thumbLink.data('gallery-index');
      var $productEl = prodcat.ui.getProductEl($thumbLink);

      Drupal.behaviors.productGalleryV1.selectImage($('.js-product-gallery', $productEl), idx);
    }
  );

  Drupal.behaviors.productGalleryV1 = {
    hideSmoosh: false,
    attach: function (context, settings) {
      if (settings && settings.bb_product) {
        this.hideSmoosh = settings.bb_product.spp_hide_smoosh;
      }

      this.init($('.js-product-gallery', context));
    },
    _initZoom: function ($gallery) {
      var $largeImg = $('.js-gallery-img-large.active', $gallery);

      if ($largeImg.parents('.js-quickshop').length > 0) {
        return null;
      }
      $largeImg.elevateZoom({
        zoomWindowPosition: 1,
        zoomWindowOffetx: 10,
        zoomWindowWidth: 550,
        zoomWindowHeight: 450,
        borderSize: 0
      });
    },
    _removeZoom: function ($gallery) {
      var $largeImg = $('.js-gallery-img-large.active', $gallery);

      $.removeData($largeImg, 'elevateZoom'); // remove zoom instance from image
      $('.zoomContainer', $gallery).remove(); // remove zoom container from DOM
    },
    init: function ($gallery) {
      if (!$gallery || $gallery.length < 1) {
        return null;
      }
      // var $largeImages = $(".js-product-gallery__main img", $gallery);
      // var $thumbDivs = $(".js-product-gallery__div-thumb--smoosh, js-product-gallery__div-thumb--product", $gallery);

      // build carousel for >4 images
      var carouselBreakPoint = 4;

      if ($('.js-product-gallery__thumb', $gallery).length > carouselBreakPoint) {
        $('.js-product-gallery__thumbs', $gallery).slick({
          infinite: false,
          slidesToShow: carouselBreakPoint,
          slidesToScroll: 1,
          centerMode: true
        });
      }
      if (!Modernizr.touch) {
        this._initZoom($gallery);
      }
      if ($gallery.hasClass('product-gallery--shaded')) {
        var skuBaseId = $gallery.data('sku-base-id');

        $('.js-swatch-list-item-link[data-sku-base-id="' + skuBaseId + '"]').trigger('click');
      }
    }, // end init
    displaySmoosh: function ($gallery, skuBaseId) {
      if (this.hideSmoosh) {
        return null;
      }
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!_.isNull(skuData)) {
        if (_.isArray(skuData.IMAGE_SMOOSH_L) && skuData.IMAGE_SMOOSH_L.length) {
          $('.js-product-gallery__photo--smoosh', $gallery)
            .attr('src', skuData.IMAGE_SMOOSH_L[0])
            .data('zoom-image', skuData.IMAGE_SMOOSH_XL[0]);
          this.selectImage($gallery, 1);
          $('.js-product-gallery__img-thumb--smoosh', $gallery).attr('src', skuData.IMAGE_SMOOSH_S[0]);
        }
      }
    },
    displaySkuImage: function ($gallery, skuBaseId) {
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!_.isNull(skuData)) {
        if (_.isArray(skuData.IMAGE_SKU_L) && skuData.IMAGE_SKU_L.length) {
          $('.js-product-gallery__photo--product', $gallery)
            .attr('src', skuData.IMAGE_SKU_L[0])
            .data('zoom-image', skuData.IMAGE_SKU_XL[0]);
          var $thumbimg = $('.js-product-gallery__img-thumb--product', $gallery);

          $thumbimg.attr('src', skuData.IMAGE_SKU_S[0]);
        }
      }
    },
    displaySkuImageSized: function ($gallery, skuBaseId) {
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!_.isNull(skuData)) {
        if (_.isArray(skuData.IMAGE_SKU_L) && skuData.IMAGE_SKU_L.length) {
          $('.js-product-gallery__photo--product', $gallery)
            .attr('src', skuData.IMAGE_SKU_L[0])
            .data('zoom-image', skuData.IMAGE_SKU_XL[0]);
          var $thumbimg = $('.js-product-gallery__img-thumb--product', $gallery);

          $thumbimg.attr('src', skuData.IMAGE_SKU_S[0]);
        }
      }
    },
    selectImage: function ($gallery, idx) {
      var $largeImages = $('.js-product-gallery__main img', $gallery);
      var $thumbDivs = $('.js-product-gallery__div-thumb--smoosh, js-product-gallery__div-thumb--product', $gallery);

      if (!Modernizr.touch) {
        this._removeZoom($gallery);
      }
      $largeImages.removeClass('active');
      $thumbDivs.removeClass('active');
      $('[data-gallery-index=' + idx + ']', $gallery).addClass('active');
      if (!Modernizr.touch) {
        this._initZoom($gallery);
      }
    }
  }; // end Drupal.behaviors.productGalleryV1
})(jQuery);
